import React, { useEffect, useState } from 'react';
import problemsData from '../data/problems.json'; // Import the JSON data
import axios from 'axios';
import '../pages/ProjectPage.css';
import { BACKEND_URL } from '../globals'

const API_URL = BACKEND_URL;

const IdentifyingProblem = ({ selectedAreas, setSelectedAreas, ideas, setIdeas, isProblemLocked, setIsProblemLocked, setActiveTab, projectId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [finalizedProblem, setFinalizedProblem] = useState({
    title: '',
    description: '',
    affected: ''
  });

  useEffect(() => {
    const fetchFinalizedProblem = async () => {
      try {
        const token = localStorage.getItem('token');
        console.log('Fetching finalized problem for project ID:', projectId);
        const response = await axios.get(`${API_URL}/api/projects/${projectId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        const { finalizedProblem, generatedProblems } = response.data;
        if (finalizedProblem) {
          setFinalizedProblem(finalizedProblem);
          if (finalizedProblem.title || finalizedProblem.description || finalizedProblem.affected) {
            setIsProblemLocked(true);
            console.log('Finalized problem fields are not empty, locking problem');
          } else {
            setIsProblemLocked(false);
            console.log('Finalized problem fields are empty, not locking problem');
          }
        }

        if (generatedProblems) {
          setIdeas(generatedProblems);
          console.log('Generated Problems:', generatedProblems);
        }
      } catch (error) {
        console.error('Error fetching finalized problem:', error);
      }
    };

    fetchFinalizedProblem();
  }, [projectId, setIsProblemLocked, setIdeas]);

  const handleAreaChange = (index, value) => {
    const newSelectedAreas = [...selectedAreas];
    newSelectedAreas[index] = value;
    setSelectedAreas(newSelectedAreas);
  };

  const handleSubmit = () => {
    if (selectedAreas.some(area => area.trim() !== '')) {
      const newIdeas = selectedAreas.map(area => {
        if (area) {
          const areaProblems = problemsData.find(data => data.area === area)?.problems || [];
          return areaProblems[Math.floor(Math.random() * areaProblems.length)];
        }
        return null;
      });
      setIdeas(newIdeas);
      setErrorMessage('');
    } else {
      setErrorMessage('Please select at least one problem area.');
    }
  };

  const handleFinalizeChange = (e) => {
    const { name, value } = e.target;
    setFinalizedProblem(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFinalizeSubmit = async () => {
    setIsProblemLocked(true);
    console.log('Finalized Problem:', finalizedProblem);
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/api/projects/${projectId}/finalizeProblem`, finalizedProblem, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setActiveTab('General Idea');
    } catch (error) {
      console.error('Error finalizing problem:', error);
    }
  };

  return (
    <div className="tab-content">
      <div className="content-box">
        <h2>Choosing a Problem</h2>
        <p>
          Identifying a problem is the first and most crucial step in the journey of any entrepreneur.
          By identifying a valid and severe pain point, entrepreneurs ensure that their project has an audience.
          The majority of failed startups attempt to shoehorn a solution into a problem, while the most successful
          ones build something others need.
        </p>
        <div className="video-container">
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/hw8FfogTUhU" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
            className="video">
          </iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/jnpS3Uv85SE" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
            className="video">
          </iframe>
        </div>
      </div>
      <div className='content-box'>
        <h2>Picking Problem Area</h2>
        <p>
          Select 3 areas that you would like help brainstorming solutions for! We recommend that these be problems you
          are personally passionate about or personally affected by to ensure that you can remain motivated throughout
          the strenuous process of building a passion project or a startup.
        </p>
        <div className="input-row">
          {selectedAreas.map((area, index) => (
            <div key={index} className="dropdown-container">
              <select
                value={area}
                onChange={(e) => handleAreaChange(index, e.target.value)}
                className="problem-dropdown"
                disabled={isProblemLocked}
              >
                <option value="" disabled>Select Problem Area {index + 1}</option>
                {problemsData.map((data) => (
                  <option key={data.area} value={data.area} disabled={selectedAreas.includes(data.area)}>
                    {data.area}
                  </option>
                ))}
              </select>
            </div>
          ))}
          {!isProblemLocked && (
            <button onClick={handleSubmit} className="submit-button">
              {ideas.some(idea => idea !== null) ? 'Regenerate' : 'Submit'}
            </button>
          )}
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="ideas-container">
          {ideas.map((idea, index) => (
            idea && (
              <div key={index} className="idea-box">
                <h3>{selectedAreas[index]}</h3>
                <p><strong>Problem:</strong> {idea.problem}</p>
                <p><strong>Description:</strong> {idea.description}</p>
                <p><strong>Affected:</strong> {idea.affected}</p>
              </div>
            )
          ))}
        </div>
      </div>
      <div className='content-box'>
        <h2>Finalize a Problem</h2>
        <div className="input-row finalize-row">
          <textarea
            name="title"
            value={finalizedProblem.title}
            onChange={handleFinalizeChange}
            className="finalize-textbox"
            placeholder="Problem Title"
            disabled={isProblemLocked}
          />
        </div>
        <div className="input-row finalize-row">
          <textarea
            name="description"
            value={finalizedProblem.description}
            onChange={handleFinalizeChange}
            className="finalize-textbox"
            placeholder="Problem Description"
            disabled={isProblemLocked}
          />
        </div>
        <div className="input-row finalize-row">
          <textarea
            name="affected"
            value={finalizedProblem.affected}
            onChange={handleFinalizeChange}
            className="finalize-textbox"
            placeholder="People Affected"
            disabled={isProblemLocked}
          />
          <button onClick={handleFinalizeSubmit} className="submit-button" disabled={isProblemLocked}>
            Finalize
          </button>
        </div>
      </div>
    </div>
  );
};

export default IdentifyingProblem;
