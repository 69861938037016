import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import Navbar from './components/General/Navbar';
import Join from './pages/Join';
import Login from './pages/Login';
import Footer from './components/General/Footer';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import AuthProvider from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import ProjectPage from './pages/ProjectPage';

// Initialize GA
ReactGA.initialize('G-2BBXW1C1PB');

function App() {
  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <AppContent />
        <Footer />
      </AuthProvider>
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/join" element={<Join />} />
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/projects/:id" element={<ProjectPage />} />
      </Route>
    </Routes>
  );
}

export default App;