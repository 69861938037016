import React from 'react';
import HomeBase from '../components/Home/HomeBase';
import UniversityCarousel from '../components/Home/UniversityCarousel';
import Features from '../components/Home/Features';
import Testimonials from '../components/Home/Testimonials';

const Home = () => {
  return (
    <div>
      <HomeBase />
      <UniversityCarousel />
      <Features />
      <Testimonials />
    </div>
  );
};

export default Home;
