import React, { useEffect, useState } from 'react';
import './UniversityCarousel.css';
import universities from '../../data/universities.json';

const UniversityCarousel = () => {
  const [duplicatedUniversities, setDuplicatedUniversities] = useState([]);

  useEffect(() => {
    // Duplicate the universities array to ensure continuous scrolling
    setDuplicatedUniversities([...universities, ...universities, ...universities, ...universities, ...universities]);
  }, []);

  return (
    <div className="carousel-container">
      <h2>Trusted by students at</h2>
      <div className="carousel">
        <div className="carousel-track">
          {duplicatedUniversities.map((university, index) => (
            <a
              key={index}
              href={university.link}
              target="_blank"
              rel="noopener noreferrer"
              className="carousel-item"
            >
              <img
                src={university.image}
                alt={university.name}
                className="carousel-logo"
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UniversityCarousel;
