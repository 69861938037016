import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../AuthContext';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Dashboard.css';
import { BACKEND_URL } from '../globals'

const API_URL = BACKEND_URL;

const Dashboard = () => {
  const { user, fetchUser } = useContext(AuthContext);
  const [title, setTitle] = useState('');
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (user && user.projects && Array.isArray(user.projects)) {
      const projectIds = user.projects.map(project => project._id);
      fetchProjects(projectIds);
    }
  }, [user]);

  const fetchProjects = async (projectIds) => {
    try {
      console.log('Fetching projects with IDs:', projectIds);
      const projectResponses = await Promise.all(
        projectIds.map((projectId) => {
          console.log('projectId:', projectId, 'Type:', typeof projectId);
          const projectIdStr = projectId.toString();
          console.log('Fetching project with ID:', projectIdStr);
          return axios.get(`${API_URL}/api/projects/${projectIdStr}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
          });
        })
      );
      const fetchedProjects = projectResponses.map((res) => res.data);
      console.log('Fetched projects:', fetchedProjects);
      setProjects(fetchedProjects);
    } catch (error) {
      console.error('Error fetching projects:', error.response ? error.response.data : error.message);
    }
  };

  const handleCreateProject = async (e) => {
    e.preventDefault();
    try {
      if (projects.length > 0) {
        alert('You can only have one project at a time.');
        return;
      }
  
      const token = localStorage.getItem('token');
  
      const response = await axios.post(`${API_URL}/api/projects/create`, {
        title,
        description: 'A wonderful project!',
        generatedProblems: [],
        finalizedProblem: {},
        experiences: [],
        generatedIdeas: [],
        finalizedIdea: {}
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
  
      setProjects([...projects, response.data]);
      setTitle('');
  
      // Fetch updated user data after creating a new project
      await fetchUser(token);
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };  

  return (
    <div className="dashboard-container">
      <div className="base-container">
        <div className="user-info">
          <h1>{user?.name}</h1>
          <p>{user?.bio}</p>
        </div>
      </div>
      <div className="general-info-section">
        <h2>General Information</h2>
        <p><strong>School:</strong> {user?.organization}</p>
        <p><strong>Major:</strong> {user?.major}</p>
      </div>
      <div className="general-info-section">
        <h2>Entrepreneurship Education</h2>
        <p>Create your first project to learn about and participate in the several steps necessary to create a running business. Walk through the steps from Problem Selection to Idea Generation to Marketing and Branding with the assistance of our AI engine!</p>
      </div>
      <div className="projects-section">
        <h2>Projects</h2>
        {projects.length > 0 ? (
          projects.map((project) => (
            <Link to={`/projects/${project._id}`} key={project._id} className="project-card">
              <div>
                <h3>{project.title}</h3>
                <p>{project.description}</p>
              </div>
            </Link>
          ))
        ) : (
          <p>No projects yet.</p>
        )}
        {projects.length === 0 && (
          <form className="create-project-form" onSubmit={handleCreateProject}>
            <button type="submit" className="create-project-button">
              Create
            </button>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Temporary Project Title"
              className="project-title-input"
            />
          </form>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
