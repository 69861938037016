import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from './logo-rect.png';
import { AuthContext } from '../../AuthContext';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navigateToSignUp = () => {
    navigate('/join');
  };

  const navigateToLogin = () => {
    navigate('/login');
  };

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <div className="navbar-left">
        <Link to="/">
          <img src={logo} alt="AIRchemedes" className="logo" />
        </Link>
      </div>
      <div className={`navbar-right ${isMobileMenuOpen ? 'open' : ''}`}>
        <a href="https://www.venturecamp.org/" className="navbar-link" target="_blank" rel="noopener noreferrer">
          Venture Camp
        </a>
        {user ? (
          <>
            <button className="dashboard-button" onClick={navigateToDashboard}>
              Dashboard
            </button>
            <button className="logout-button" onClick={logout}>
              Log Out
            </button>
          </>
        ) : (
          <>
            <button className="signup-button" onClick={navigateToSignUp}>
              Sign Up
            </button>
            <button className="login-button" onClick={navigateToLogin}>
              Log In
            </button>
          </>
        )}
      </div>
      <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
        &#9776; {/* Hamburger menu icon */}
      </div>
    </nav>
  );
};

export default Navbar;
