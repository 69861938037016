import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import IdentifyingProblem from '../components/IdentifyingProblem';
import GeneralIdea from '../components/GeneralIdea';
import './ProjectPage.css';
import { BACKEND_URL } from '../globals'

const API_URL = BACKEND_URL;

const ProjectPage = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [activeTab, setActiveTab] = useState('Identifying a Problem');
  const [selectedAreas, setSelectedAreas] = useState(['', '', '']);
  const [ideas, setIdeas] = useState([null, null, null]);
  const [isProblemLocked, setIsProblemLocked] = useState(false);
  const [experiences, setExperiences] = useState([
    { company: '', role: '', description: '' },
    { company: '', role: '', description: '' }
  ]);
  const [isExperiencesLocked, setIsExperiencesLocked] = useState(false);
  const [generatedIdeas, setGeneratedIdeas] = useState([]);
  const [finalizedIdea, setFinalizedIdea] = useState({
    title: '',
    description: '',
    nextSteps: ''
  });
  const [isIdeaLocked, setIsIdeaLocked] = useState(false);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/api/projects/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setProject(response.data);
        
        if (response.data.finalizedProblem) {
          setIsProblemLocked(true);
        }
        if (response.data.experiences && response.data.experiences.length > 0) {
          setExperiences(response.data.experiences);
          setIsExperiencesLocked(true);
        }
        if (response.data.generatedIdeas && response.data.generatedIdeas.length > 0) {
          setGeneratedIdeas(response.data.generatedIdeas);
        }
        if (response.data.finalizedIdea && response.data.finalizedIdea.title) {
          setFinalizedIdea(response.data.finalizedIdea);
          setIsIdeaLocked(true);
        }
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    };

    fetchProject();
  }, [id]);

  const handleGenerateIdeas = async (newExperiences) => {
    setIsExperiencesLocked(true);
    setExperiences(newExperiences);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(
        `${API_URL}/api/projects/${id}/generateIdeas`,
        {
          experiences: newExperiences,
          finalizedProblem: project.finalizedProblem
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setGeneratedIdeas(response.data.ideas);
    } catch (error) {
      console.error('Error generating ideas:', error);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'Identifying a Problem':
        return (
          <IdentifyingProblem
            selectedAreas={selectedAreas}
            setSelectedAreas={setSelectedAreas}
            ideas={ideas}
            setIdeas={setIdeas}
            isProblemLocked={isProblemLocked}
            setIsProblemLocked={setIsProblemLocked}
            setActiveTab={setActiveTab}
            projectId={id}
          />
        );
      case 'General Idea':
        return (
          <GeneralIdea
            project={project}
            experiences={experiences}
            setExperiences={setExperiences}
            isExperiencesLocked={isExperiencesLocked}
            setIsExperiencesLocked={setIsExperiencesLocked}
            generatedIdeas={generatedIdeas}
            setGeneratedIdeas={setGeneratedIdeas}
            finalizedIdea={finalizedIdea}
            setFinalizedIdea={setFinalizedIdea}
            isIdeaLocked={isIdeaLocked}
            setIsIdeaLocked={setIsIdeaLocked}
            handleGenerateIdeas={handleGenerateIdeas}
            projectId={id}
          />
        );
      case 'Branding':
        return <p>This is the Branding content.</p>;
      case 'Industry Analysis':
        return <p>This is the Industry Analysis content.</p>;
      case 'Marketing Strategy':
        return <p>This is the Marketing Strategy content.</p>;
      case 'Business Canvas':
        return <p>This is the Business Canvas content.</p>;
      default:
        return <p>Select a tab to view content.</p>;
    }
  };

  const handleTabClick = (tab) => {
    const allowedTabs = ['Identifying a Problem', 'General Idea'];
    if (allowedTabs.includes(tab) && (tab !== 'General Idea' || isProblemLocked)) {
      setActiveTab(tab);
    }
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="project-page-container">
      <div className="project-header">
        <h1>{project.title}</h1>
        <p>{project.description}</p>
      </div>
      <div className="project-tabs">
        <ul>
          {['Identifying a Problem', 'General Idea', 'Branding', 'Industry Analysis', 'Marketing Strategy', 'Business Canvas'].map(tab => (
            <li
              key={tab}
              className={`${activeTab === tab ? 'active' : ''} ${!['Identifying a Problem', 'General Idea'].includes(tab) || (tab === 'General Idea' && !isProblemLocked) ? 'disabled' : ''}`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>
      <div className="project-content">
        <div className="project-details">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;