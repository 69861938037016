import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomeBase.css';

const HomeBase = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/join');
  };

  return (
    <div className="homebase-container">
      <h1 className="homebase-title">The Ultimate AI Entrepreneurship Assistant</h1>
      <p className="homebase-subtitle">The AI-powered tool to turn your hobbies and skills into passion projects</p>
      <div className="homebase-buttons">
        <button className="homebase-button" onClick={handleGetStarted}>Get Started</button>
      </div>
    </div>
  );
};

export default HomeBase;
