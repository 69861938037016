import React, { useState, useContext } from 'react';
import './Join.css'; // Reuse the same CSS as Join
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

const Login = () => {
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await login(loginData.email, loginData.password);
      navigate('/dashboard'); // Redirect to a protected route, e.g., dashboard
    } catch (err) {
      setError(err.message || 'Login failed');
    }
  };

  return (
    <div className="join-container">
      <div className="join-left">
        <p className="join-quote">
          "AIrchemedes let me design a startup that somehow connected all of my hobbies. It was something I could really get behind." - Wilson Cheung, Harvard
        </p>
      </div>
      <div className="join-right">
        <form className="join-form" onSubmit={handleSubmit}>
          <h1>Login</h1>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <label>
            Email:
            <input type="email" name="email" value={loginData.email} onChange={handleChange} required />
          </label>
          <label>
            Password:
            <input type="password" name="password" value={loginData.password} onChange={handleChange} required />
          </label>
          <button type="submit">Log In</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
