import React from 'react';
import './Footer.css';
import logo from './/logo-rect.png';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-logo">
        <img src={logo} alt="AIrchemedes" />
      </div>
      <div className="footer-right">
        <span>dhruv@venturecamp.org</span>
      </div>
    </footer>
  );
};

export default Footer;
