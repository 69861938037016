import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios
import { BACKEND_URL } from './globals'

export const AuthContext = createContext();

const API_URL = BACKEND_URL;

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const fetchUser = useCallback(async (token) => {
    try {
      const response = await axios.get(`${API_URL}/api/users/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        console.log('Fetched user data:', response.data);
        setUser(response.data);
      } else {
        console.error('Failed to fetch user data:', response.data);
        localStorage.removeItem('token');
        setUser(null);
        navigate('/login');
      }
    } catch (error) {
      console.error('Failed to fetch user', error);
      localStorage.removeItem('token');
      setUser(null);
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUser(token);
    }
  }, [fetchUser]);

  const login = async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/api/users/login`, {
        email,
        password
      });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        setUser(response.data.user);
        navigate('/dashboard'); // Navigate to dashboard or home after login
        return { success: true };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, fetchUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
