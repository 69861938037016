import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typewriter from '../components/Typewriter';
import './Join.css';
import { BACKEND_URL } from '../globals'

const API_URL = BACKEND_URL;

const Join = () => {
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    phone: ''
  });
  const [infoData, setInfoData] = useState({
    name: '',
    organization: '',
    major: '',
    agreeToNewsletter: false
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (e) => {
    let { value } = e.target;
    value = value.replace(/\D/g, ''); // Remove non-numeric characters
    if (value.length > 3 && value.length <= 6) {
      value = `(${value.slice(0, 3)})-${value.slice(3)}`;
    } else if (value.length > 6) {
      value = `(${value.slice(0, 3)})-${value.slice(3, 6)}-${value.slice(6, 10)}`;
    }
    setFormData({ ...formData, phone: value });
  };

  const handleInfoChange = (e) => {
    const { name, value, type, checked } = e.target;
    setInfoData({ ...infoData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(formData.password)) {
      alert('Password must be at least 8 characters long and contain at least one uppercase letter, one digit, and one special character.');
      return;
    }
    setIsSignedUp(true);
  };


const handleInfoSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  const bio = `${infoData.major} at ${infoData.organization}`;
  const user = {
    email: formData.email,
    password: formData.password,
    phone: formData.phone,
    name: infoData.name,
    organization: infoData.organization,
    major: infoData.major,
    agreeToNewsletter: infoData.agreeToNewsletter,
    bio,
  };

  try {
    const response = await fetch(`${API_URL}/api/users`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(user),
    });

    const responseData = await response.json();
    console.log('Server Response:', responseData);

    if (response.ok) {
      alert('User registered successfully');
      navigate('/login');
    } else {
      alert(`Failed to register: ${responseData.message || response.statusText}`);
    }
  } catch (error) {
    console.error('Error:', error);
    alert('An error occurred while registering');
  } finally {
    setLoading(false);
  }
};

  return (
    <div className="join-container">
      <div className="join-left">
        <p className="join-quote">
          "AIrchemedes let me design a startup that somehow connected all of my hobbies. It was something I could really get behind." - Wilson Cheung, Harvard
        </p>
      </div>
      <div className="join-right">
        {!isSignedUp ? (
          <form className="join-form" onSubmit={handleSubmit}>
            <h1>Join Us</h1>
            <label>
              Email:
              <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </label>
            <label>
              Phone Number:
              <input type="tel" name="phone" value={formData.phone} onChange={handlePhoneChange} required />
            </label>
            <label>
              Password:
              <input type="password" name="password" value={formData.password} onChange={handleChange} required />
            </label>
            <label>
              Confirm Password:
              <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
            </label>
            <button type="submit">Sign Up</button>
          </form>
        ) : (
          <form className="info-form" onSubmit={handleInfoSubmit}>
            <Typewriter text="Let's get started. Can you tell me your name, where you go to school, and what you major in or are thinking about majoring in?" />
            <label>
              Your name:
              <input type="text" name="name" value={infoData.name} onChange={handleInfoChange} required />
            </label>
            <label>
              Your school:
              <input type="text" name="organization" value={infoData.organization} onChange={handleInfoChange} required />
            </label>
            <label>
              Your major:
              <input type="text" name="major" value={infoData.major} onChange={handleInfoChange} required />
            </label>
            <div className="label-row">
              <span>Receive marketing materials:</span>
              <input
                type="checkbox"
                name="agreeToNewsletter"
                checked={infoData.agreeToNewsletter}
                onChange={handleInfoChange}
              />
            </div>
            <div className="submit-row">
              {loading && <div className="loader"></div>}
              <button type="submit">Submit</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Join;
