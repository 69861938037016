import React from 'react';
import './Features.css';

const Features = () => {
  return (
    <div className="features-container">
      <h2 className="features-title">Features</h2>
      <div className="features-boxes">
        <div className="feature-box">
          <h3 className="feature-title">Generate Ideas</h3>
          <p className="feature-description">Go from your hobbies and skills to an actionable idea for a startup or passion project!</p>
          <ul className="feature-points">
            <li>Find problems in fields that interest you</li>
            <li>Conduct mock user interviews with our bot to understand the problem</li>
            <li>Brainstorm potential solutions to your issues</li>
          </ul>
        </div>
        <div className="feature-box">
          <h3 className="feature-title">Maketing</h3>
          <p className="feature-description">Develop an outreach strategy to ensure your business gets more traction!</p>
          <ul className="feature-points">
            <li>Use our bot to produce branding materials</li>
            <li>Get suggestions for outreach channels and methods</li>
            <li>Develop a plan of action for spreading the word about your project</li>
          </ul>
        </div>
        <div className="feature-box">
          <h3 className="feature-title">Business Model</h3>
          <p className="feature-description">Produce a comprehensive business model to help others understand your business!</p>
          <ul className="feature-points">
            <li>An easy, exportable way for users to organize their business</li>
            <li>Information on the industry and scope</li>
            <li>Analysis of where the opportunity lies</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Features;
