import React, { useState } from 'react';
import axios from 'axios';
import '../pages/ProjectPage.css';
import { BACKEND_URL } from '../globals'

const API_URL = BACKEND_URL;

const GeneralIdea = ({ 
  project,
  experiences,
  setExperiences,
  isExperiencesLocked,
  setIsExperiencesLocked,
  generatedIdeas,
  setGeneratedIdeas,
  finalizedIdea,
  setFinalizedIdea,
  isIdeaLocked,
  setIsIdeaLocked,
  handleGenerateIdeas,
  projectId
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isGeneratingIdeas, setIsGeneratingIdeas] = useState(false);

  const handleExperienceChange = (index, e) => {
    const { name, value } = e.target;
    const newExperiences = [...experiences];
    newExperiences[index][name] = value;
    setExperiences(newExperiences);
  };

  const handleGenerateIdeasClick = async () => {
    setIsGeneratingIdeas(true);
    setErrorMessage('');
    try {
      await handleGenerateIdeas(experiences);
    } catch (error) {
      console.error('Error generating ideas:', error);
      setErrorMessage('Failed to generate ideas. Please try again.');
    } finally {
      setIsGeneratingIdeas(false);
    }
  };

  const handleFinalizeChange = (e) => {
    const { name, value } = e.target;
    setFinalizedIdea(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFinalizeSubmit = async () => {
    if (!finalizedIdea.title || !finalizedIdea.description) {
      setErrorMessage('Please provide both a title and description for your idea.');
      return;
    }

    setErrorMessage('');
    const token = localStorage.getItem('token');
    try {
      await axios.put(`${API_URL}/api/projects/${projectId}/finalizeIdea`, finalizedIdea, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setIsIdeaLocked(true);
      setShowMessage(true);
    } catch (error) {
      console.error('Error finalizing idea:', error);
      setErrorMessage('An error occurred while saving your idea. Please try again.');
    }
  };

  const areExperiencesFilled = experiences.every(exp => exp.company && exp.role && exp.description);

  return (
    <div className="tab-content">
      <div className="content-box">
        <h2>Idea Generation</h2>
        <p>
          The next step to building any startup or passion project is coming up with an idea! Using your selected problem, we will help generate ideas for you.
        </p>
        <div className="video-container">
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/vDXkpJw16os" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
            className="video">
          </iframe>
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/P2xXT_FU-QE" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
            className="video">
          </iframe>
        </div>
      </div>
      <div className="content-box">
        <h2>Relevant Experiences</h2>
        <p>Please share any relevant experiences you have related to building apps or products that can help out AI effectively generate ideas that will play to your strengths!</p>
        {experiences.map((exp, index) => (
          <div key={index} className="experience-section">
            <h3>Experience {index + 1}</h3>
            <input
              type="text"
              name="company"
              value={exp.company}
              onChange={(e) => handleExperienceChange(index, e)}
              placeholder="Company"
              className="experience-input"
              disabled={isExperiencesLocked}
            />
            <input
              type="text"
              name="role"
              value={exp.role}
              onChange={(e) => handleExperienceChange(index, e)}
              placeholder="Role"
              className="experience-input"
              disabled={isExperiencesLocked}
            />
            <textarea
              name="description"
              value={exp.description}
              onChange={(e) => handleExperienceChange(index, e)}
              placeholder="Description"
              className="experience-textarea"
              disabled={isExperiencesLocked}
            />
          </div>
        ))}
      </div>
      <div className="content-box">
        <h2>Generate Ideas</h2>
        <div className="generate-ideas-container">
          <p className="generate-ideas-text">
            Using the information you have provided about your relevant experiences, as well as the problem that you have given us, we will generate 2 possible ideas you can pursue for a startup!
          </p>
          <button 
            onClick={handleGenerateIdeasClick} 
            className={`generate-button ${(!areExperiencesFilled || isExperiencesLocked || isGeneratingIdeas) ? 'disabled' : ''}`}
            disabled={!areExperiencesFilled || isExperiencesLocked || isGeneratingIdeas}
          >
            {isGeneratingIdeas ? 'Generating...' : 'Generate Ideas'}
          </button>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="ideas-container">
          {generatedIdeas.map((idea, index) => (
            <div key={index} className="idea-box">
              <h3>{idea.title}</h3>
              <p><strong>Description:</strong> {idea.description}</p>
              <p><strong>Next Steps:</strong> {idea.nextSteps}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="content-box">
        <h2>Finalize Your Idea</h2>
        <div className="input-row finalize-row">
          <textarea
            name="title"
            value={finalizedIdea.title}
            onChange={handleFinalizeChange}
            className="finalize-textbox"
            placeholder="Enter your idea title"
            disabled={isIdeaLocked}
            required
          />
        </div>
        <div className="input-row finalize-row">
          <textarea
            name="description"
            value={finalizedIdea.description}
            onChange={handleFinalizeChange}
            className="finalize-textbox"
            placeholder="Describe your idea"
            disabled={isIdeaLocked}
            required
          />
        </div>
        <div className="input-row finalize-row">
          <textarea
            name="nextSteps"
            value={finalizedIdea.nextSteps}
            onChange={handleFinalizeChange}
            className="finalize-textbox"
            placeholder="Outline the next steps"
            disabled={isIdeaLocked}
          />
          <button 
            onClick={handleFinalizeSubmit} 
            className="submit-button finalize-button" 
            disabled={isIdeaLocked}
          >
            Finalize Idea
          </button>
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {showMessage && (
          <p className="success-message">
            We will be rolling out more features in the coming weeks! We will send all users emails when they can move further with their project!
          </p>
        )}
      </div>
    </div>
  );
};

export default GeneralIdea;